import Header from "../components/Header";
import Map from "../components/Map";
function ContactInfoPage() {
  return (
    <>
      <Header />
      <Map />;
    </>
  );
}

export default ContactInfoPage;
