import { FooterDiv, FooterText } from "./StyledComponent";

function Footer() {
  return (
    <FooterDiv>
      <FooterText>სამტრედიის ქუჩა N3ა, Tbilisi, Georgia</FooterText>
    </FooterDiv>
  );
}

export default Footer;
