import img1 from "../assets/ბეთანია (280 კვ )/328230857_578606577137862_8907909202041273122_n.jpeg";
import img2 from "../assets/ბეთანია (280 კვ )/328247863_2096483807188670_1580201956797342452_n.jpeg";
import img3 from "../assets/ბეთანია (280 კვ )/328250200_628838072383745_1900818874595419341_n.jpeg";
import img4 from "../assets/ბეთანია (280 კვ )/328279369_883927269497913_73600836019686304_n.jpeg";
import img5 from "../assets/ბეთანია (280 კვ )/328286453_1042742823208884_6661579829537243131_n.jpeg";
import img6 from "../assets/ბეთანია (280 კვ )/328442345_551931530212848_4745040942795028776_n.jpeg";
import img7 from "../assets/ბოლნისი (180 კვ )/329482977_2258398621009218_4892861791528774331_n.jpeg";
import img8 from "../assets/ბოლნისი (180 კვ )/329567240_2996038100704620_5415155005774273221_n.jpeg";
import img9 from "../assets/ბოლნისი (180 კვ )/329573840_929731371723710_1792459649033569417_n.jpeg";
import img10 from "../assets/ბოლნისი (180 კვ )/329582604_558579749658840_3294980678989098574_n.jpeg";
import img11 from "../assets/ბოლნისი (180 კვ )/329662907_653202483159254_6279853303262953985_n.jpeg";
import img12 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/327058955_5987574461302874_8415439969087552599_n.jpeg";
import img13 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/327883734_484439240545486_6784341216344209557_n.jpeg";
import img14 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/327960356_853313835929698_7025818692160268652_n.jpeg";
import img15 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/328129219_628224985974379_7496713019903930549_n.jpeg";
import img16 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/328229139_945337726875571_39475471860023961_n.jpeg";
import img17 from "../assets/დაბა კოჯორი, სოფელი დიდება (60კვ)/328260149_5813797045372609_8453818002155544590_n.jpeg";
import img18 from "../assets/დიდი დიღომი (230 კვ )/361597653_223820357285058_7473092352123331858_n.jpeg";
import img19 from "../assets/დიდი დიღომი (230 კვ )/361625464_223820360618391_6753784060155944695_n.jpeg";
import img20 from "../assets/დიდი დიღომი (230 კვ )/361636804_223820337285060_4697914613435968512_n.jpeg";
import img21 from "../assets/დიდი დიღომი (230 კვ )/361664948_223820373951723_1332657291315899175_n.jpeg";
import img22 from "../assets/ივერთუბანი (550კვ)/342785838_774686677657481_4739219215165932643_n.jpeg";
import img23 from "../assets/ივერთუბანი (550კვ)/342849944_121692640894569_9037529387897489348_n.jpeg";
import img24 from "../assets/ივერთუბანი (550კვ)/342916518_198596042639736_3694491213968076502_n.jpeg";
import img25 from "../assets/კოჯორი (200 კვ )/351174543_1330384237824285_6016275242316860880_n.jpeg";
import img26 from "../assets/კოჯორი (200 კვ )/351333590_262336673133492_1722934288348343284_n.jpeg";
import img27 from "../assets/კოჯორი (200 კვ )/351344011_1741003779691422_6125609921918421506_n.jpeg";
import img28 from "../assets/კოჯორი (480 კვ)/327124883_1406623210094283_4623963895993630287_n.jpeg";
import img29 from "../assets/კოჯორი (480 კვ)/327401569_949543403088443_4972340512713758670_n.jpeg";
import img30 from "../assets/კოჯორი (480 კვ)/327451583_858314195440950_7591492365925751494_n.jpeg";
import img31 from "../assets/კოჯორი (480 კვ)/327462857_3325186504403109_7508612595703112151_n.jpeg";
import img32 from "../assets/კოჯორი (480 კვ)/327721949_5824814544223020_5107029397681111565_n.jpeg";
import img33 from "../assets/ლისის ტბის კერძო დასახლება (330 კვ )/362252039_226190990381328_1452934754106116910_n.jpeg";
import img34 from "../assets/ლისის ტბის კერძო დასახლება (330 კვ )/362282187_226191013714659_7518606631033303069_n.jpeg";
import img35 from "../assets/ლისის ტბის კერძო დასახლება (330 კვ )/362586447_226190987047995_3507791247553270643_n.jpeg";
import img36 from "../assets/ლისის ტბის კერძო დასახლება (330 კვ )/362618967_226190973714663_3967765970727075426_n.jpeg";
import img37 from "../assets/მცხეთა (60 კვ )/343312526_1175838439761779_3876267590664492203_n.jpeg";
import img38 from "../assets/მცხეთა (60 კვ )/343422175_955807829190645_1523045824857988631_n.jpeg";
import img39 from "../assets/მცხეთა (60 კვ )/343589054_205265722288261_7050536458817824397_n.jpeg";
import img40 from "../assets/პოლონეთი (ბიზნეს ცენტრი )/330964821_1229802941254996_2011488318746629750_n.jpeg";
import img41 from "../assets/პოლონეთი (ბიზნეს ცენტრი )/331502071_585734016437827_2708919844444393253_n.jpeg";
import img42 from "../assets/პოლონეთი (ბიზნეს ცენტრი )/331518231_915842209763718_6864550640330396787_n.jpeg";
import img43 from "../assets/პოლონეთი (ბიზნეს ცენტრი )/331523554_918695982700445_3664209945020277947_n.jpeg";
import img44 from "../assets/პოლონეთი (ბიზნეს ცენტრი )/331553967_3044892799152941_6356874695020703073_n.jpeg";
// import img45 from "../assets/საგურამო ( 150 კვ )/332060521_779244127105418_246262695724353847_n.jpeg";
// import img46 from "../assets/საგურამო ( 150 კვ )/332078876_703844671438752_6508974064811736831_n.jpeg";
// import img47 from "../assets/საგურამო ( 150 კვ )/332085199_889422768980775_305120778596121846_n.jpeg";
// import img48 from "../assets/საგურამო ( 150 კვ )/332260565_9219447408096127_6903915294359333229_n.jpeg";
// import img49 from "../assets/საგურამო ( 150 კვ )/332274988_726551575576690_5419589446846386365_n.jpeg";
import img50 from "../assets/საგურამო ( 450 კვ)/324881789_870537947492777_4118666414614097671_n.jpeg";
import img51 from "../assets/საგურამო ( 450 კვ)/327437572_958066348513546_616107774831063502_n.jpeg";
import img52 from "../assets/საგურამო ( 450 კვ)/327568359_715400016739508_8801403685806356434_n.jpeg";
import img53 from "../assets/საგურამო (600 კვ)/339087411_231483746021196_6918944861113065851_n.jpeg";
import img54 from "../assets/საგურამო (600 კვ)/339090340_602988588514080_6323102106792433988_n.jpeg";
import img55 from "../assets/საგურამო (600 კვ)/339092290_670425998427483_6369721509036393257_n.jpeg";
import img56 from "../assets/საგურამო (600 კვ)/339747923_226384536727592_6028714806118878831_n.jpeg";
import img57 from "../assets/სასტუმრო დუშეთში (580 კვ )/358403920_217228261277601_7949252350445267265_n.jpeg";
import img58 from "../assets/სასტუმრო დუშეთში (580 კვ )/358432944_217228297944264_3111710864633520262_n.jpeg";
import img59 from "../assets/სასტუმრო დუშეთში (580 კვ )/358446521_217228244610936_8621570439819247405_n.jpeg";
import img60 from "../assets/სასტუმრო დუშეთში (580 კვ )/358737711_217228281277599_2195028409947620903_n.jpeg";
import img61 from "../assets/სოფელი მუხროვანი ( 170 კვ)/340924793_1248935952381543_4288453416284468647_n.jpeg";
import img62 from "../assets/სოფელი მუხროვანი ( 170 კვ)/340935922_1257762525117478_5298182840509045661_n.jpeg";
import img63 from "../assets/სოფელი მუხროვანი ( 170 კვ)/340946079_1586299598446999_270387485912848691_n.jpeg";
import img64 from "../assets/სოფელი მუხროვანი ( 170 კვ)/341000567_1174447213955440_876539726682698549_n.jpeg";
import img65 from "../assets/წავკისი (470 კვ )/335827986_737865091430526_2489182444180269042_n.jpeg";
import img66 from "../assets/წავკისი (470 კვ )/335829115_1940445296307721_1328174883019238186_n.jpeg";
import img67 from "../assets/წავკისი (470 კვ )/335835529_1166758237356555_519233703528660924_n.jpeg";
import img68 from "../assets/წავკისი (470 კვ )/335859460_741383470900800_409824771319359796_n.jpeg";
import img69 from "../assets/წავკისი (470 კვ )/335866184_711404110768746_4556593251625004797_n.jpeg";
import img70 from "../assets/წყნეთი ( 440 კვ )/371773393_250487834618310_5951034807277985088_n.jpeg";
import img71 from "../assets/წყნეთი ( 440 კვ )/371824569_250487831284977_1163086982309699130_n.jpeg";
import img72 from "../assets/წყნეთი ( 440 კვ )/371831176_250487847951642_8868268669768868921_n.jpeg";
import img73 from "../assets/წყნეთი ( 440 კვ )/371858264_250487807951646_6163230467609020338_n.jpeg";
export const ProjectsData = [
  {
    title: "ბეთანია",
    m2: "280 კვ",
    image: [img1, img2, img3, img4, img5, img6],
    id: 1,
  },
  {
    title: "ბოლნისი",
    m2: "180 კვ",
    image: [img7, img8, img9, img10, img11],
    id: 2,
  },
  {
    title: "დაბა კოჯორი, სოფელი დიდება",
    m2: "60კვ",
    image: [img12, img13, img14, img15, img16, img17],
    id: 3,
  },
  {
    title: "დიდი დიღომი",
    m2: "230 კვ",
    image: [img18, img19, img20, img21],
    id: 4,
  },
  {
    title: "ივერთუბანი",
    m2: "550კვ",
    image: [img22, img23, img24],
    id: 5,
  },
  {
    title: "კოჯორი",
    m2: "200 კვ",
    image: [img25, img26, img27],
    id: 6,
  },
  {
    title: "კოჯორი",
    m2: "480 კვ",
    image: [img28, img29, img30, img31, img32],
    id: 7,
  },
  {
    title: "ლისის ტბის კერძო დასახლება",
    m2: "330 კვ",
    image: [img33, img34, img35, img36],
    id: 8,
  },
  {
    title: "მცხეთა",
    m2: "60 კვ ",
    image: [img37, img38, img39],
    id: 9,
  },
  {
    title: "პოლონეთი ,ბიზნეს ცენტრი",
    m2: "480 კვ",
    image: [img40, img41, img42, img43, img44],
    id: 10,
  },
  {
    title: " საგურამო",
    m2: "150 კვ",
    image: [img40, img41, img42, img43, img44],
    id: 11,
  },
  {
    title: "საგურამო",
    m2: "450 კვ",
    image: [img50, img51, img52],
    id: 12,
  },
  {
    title: "საგურამო",
    m2: "600 კვ",
    image: [img53, img54, img55, img56],
    id: 13,
  },
  {
    title: " სასტუმრო დუშეთში",
    m2: "580 კვ",
    image: [img57, img58, img59, img60],
    id: 14,
  },
  {
    title: " სოფელი მუხროვანი",
    m2: "170 კვ",
    image: [img61, img62, img63, img64],
    id: 15,
  },
  {
    title: "წავკისი",
    m2: "470 კვ",
    image: [img65, img66, img67, img68, img69],
    id: 16,
  },
  {
    title: "წყნეთი",
    m2: "440 კვ",
    image: [img70, img71, img72, img73],
    id: 17,
  },
];
